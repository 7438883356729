// For User Authentication
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const LOGIN_FAIL = 'LOGIN_FAIL';

// For Select button
export const SELECT_GOOGLE = 'SELECT_GOOGLE';
export const NOT_SELECT_GOOGLE = 'NOT_SELECT_GOOGLE';
export const SELECT_KIJIJI = 'SELECT_KIJIJI';
export const NOT_SELECT_KIJIJI = 'NOT_SELECT_KIJIJI';

// For Posting
export const GET_POSTING_REQUEST = 'GET_POSTING_REQUEST';
export const SET_POSTING = 'SET_POSTING';
export const GET_POSTING_FAILURE = 'GET_POSTING_FAILURE';
export const SET_SEARCH_KEYWORD = 'SET_SEARCH_KEYWORD';

// For Bookmark
export const START_BOOKMARK_REQUEST = 'START_BOOKMARK_REQUEST';
export const GET_BOOKMARK_SUCCESS = 'GET_BOOKMARK_SUCCESS';
export const GET_BOOKMARK_FAILURE = 'GET_BOOKMARK_FAILURE';
export const ADD_TO_BOOKMARK_SUCCESS = 'ADD_TO_BOOKMARK_SUCCESS';
export const REMOVE_FROM_BOOKMARK = 'REMOVE_FROM_BOOKMARK';
export const CLEAR_BOOKMARK_LIST = 'CLEAR_BOOKMARK_LIST';
